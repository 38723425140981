import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useAppContext from "context/state";
import { useSession } from "next-auth/client";
// import { Button, Row, Col } from "reactstrap";

import {
  Button,
  Row,
  Col,
  Input,
  Label,
  Card,
  CardHeader,
  Container,
  CardBody,
  CardFooter,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody
} from "reactstrap";


import ManualTicket from "../MainContent/manualTicket";


const NewMenuIcon = () => (
  <div className="topnav-toggler-inner ">
    <i className="topnav-toggler-line" />
    <i className="topnav-toggler-line" />
    <i className="topnav-toggler-line" />
  </div>
);

// s_ds = show device state
const CustomNavbar = ({
  plant_id,
  currentMeter,
  startDate,
  endDate,
  financeDays,
  showGlobal,
  showSV,
  showEV,
  showST,
  show_unnormalized,
  s_ds,
}) => {

 
  const [billingOpen, setBillingOpen] = React.useState(false);

  const handleBillingClick = () => {
    setBillingOpen(true)
  };


  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const router = useRouter();

  const api = useAppContext();
  const slug = router.query;

  const [session, loading] = useSession();

  const [headerLinks, setHeaderLinks] = useState(
    session.user.plants["HEADER_MAIN_LINKS"] != undefined
      ? session.user.plants["HEADER_MAIN_LINKS"]
      : process.env.HEADER_MAIN_LINKS,
  );
  const [headerSubLinks, setHeaderSubLinks] = useState(
    session.user.plants["HEADER_SUB_LINKS"] != undefined
      ? session.user.plants["HEADER_SUB_LINKS"]
      : process.env.HEADER_SUB_LINKS,
  );
  const [headerAnalyticsLinks, setHeaderAnalyticsLinks] = useState(
    session.user.plants["HEADER_ANALYTICS_LINKS"] != undefined
      ? session.user.plants["HEADER_ANALYTICS_LINKS"]
      : process.env.HEADER_ANALYTICS_LINKS,
  );
  const [headerDashLinks, setHeaderDashLinks] = useState(
    session.user.plants["HEADER_DASH_LINKS"] != undefined
      ? session.user.plants["HEADER_DASH_LINKS"]
      : process.env.HEADER_DASH_LINKS,
  );

  console.log("headerLinks:", headerLinks);

console.log("headerSubLinks:", headerSubLinks);
console.log("process.env.HEADER_SUB_LINKS:", process.env.HEADER_SUB_LINKS);
console.log("session.user.plants:", session.user.plants);
console.log("Loaded headerSubLinks:", session.user.plants["HEADER_SUB_LINKS"]);


console.log("headerDashLinks:", headerDashLinks);
console.log("headerAnalyticsLinks:", headerAnalyticsLinks);


  const getHeaderSubLink = (item, isHidden) => {
    const router = useRouter();

    if (item == "manual-dgr") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/manual-dgr?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id !== undefined ? slug.meter_id : 0}`}
            passHref={true}
          >
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={
                router.pathname === "/manual-dgr" ? "nav-link active" : ""
              }
            >
              Daily Generation Input
            </a>
          </Link>
        </li>
      );
    }
  };

  const getHeaderLink = (item, isHidden) => {
    const router = useRouter();

    if (item == "landing-page") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/landing-page?&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/landing-page" ? "nav-link active" : ""
              }
            >
              Portfolio
            </a>
          </Link>
        </li>
      );
    }

    if (item == "plant-config") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/manage/plant-config?`}>
            <a
              className={
                router.pathname === "/manage/plant-config"
                  ? "nav-link active"
                  : ""
              }
            >
              Plant Config
            </a>
          </Link>
        </li>
      );
    } else if (item == "landing-page-v2") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/landing-page-v2?&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/landing-page-v2" ? "nav-link active" : ""
              }
            >
              Portfolio
            </a>
          </Link>
        </li>
      );
    } else if (item == "landing-page-v3") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/landing-page-v3?&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/landing-page-v3" ? "nav-link active" : ""
              }
            >
              Portfolio
            </a>
          </Link>
        </li>
      );
    } else if (item == "plantAvailability") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/plantAvailability`}>
            <a
              className={
                router.pathname === "/plantAvailability&"
                  ? "nav-link active"
                  : ""
              }
            >
              Plant Availability
            </a>
          </Link>
        </li>
      );
    } else if (item == "audit") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/audit?plant_id=${api.plant_id}&start_date=${startDate}&end_date=${endDate}&page_id=1&noise=${api.noiseTickets}&close=${api.closeTickets}&audit_id=${api.auditID}&reading_type=[]`}
          >
            <a
              className={router.pathname === "/audit" ? "nav-link active" : ""}
            >
              Audit
            </a>
          </Link>
        </li>
      );
    } else if (item == "summary") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/portfolio`}>
            <a
              className={
                router.pathname === "/portfolio" ? "nav-link active" : ""
              }
            >
              Summary
            </a>
          </Link>
        </li>
      );
    } else if (item == "geoMap") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/geoMap`}>
            <a
              className={
                router.pathname === "/geoMap&" ? "nav-link active" : ""
              }
            >
              Portfolio View
            </a>
          </Link>
        </li>
      );
    }
    else if (item == "reading ui") { // from env.dash/env.local
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/reading-dim`}>
            <a
              className={
                router.pathname === "/reading-dim" ? "nav-link active" : ""
              }
            >
             Readings
            </a>
          </Link>
        </li>
      );
    }
     else if (item == "finance") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/finance`} passHref={true}>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={
                router.pathname === "/finance" ? "nav-link active" : ""
              }
            >
              Finance
            </a>
          </Link>
        </li>
      );
    } else if (item == "meterKpi") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/meterKpi`}>
            <a
              className={
                router.pathname === "/meterKpi&" ? "nav-link active" : ""
              }
            >
              KPI Analysis
            </a>
          </Link>
        </li>
      );
    } else if (item == "meter-data") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/meter-data?plant_id=${api.plant_id}&meter_id=${slug.meter_id}&start_date=${startDate}&end_date=${endDate}&show_global=${showGlobal}&sv=${showSV}&ev=${showEV}&st=${showST}&zs=0&ze=100&show_unnormalized=${show_unnormalized}&derived_value=Pwr_Norm&all_siblings=${true}&graph_mode=meterDiagnosis`}
          >
            <a
              className={
                router.pathname === "/meter-data" ? "nav-link active" : ""
              }
            >
              Analysis Old
            </a>
          </Link>
        </li>
      );
    } else if (item == "overview") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/overview?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id !== "undefined" ? (slug.meter_id !== "0" ? slug.meter_id : 1) : 1}&start_date=${api.startDate}&end_date=${api.endDate}`}
          >
            <a
              className={
                router.pathname === `/overview` ? "nav-link active" : ""
              }
            >
              Overview
            </a>
          </Link>
        </li>
      );
    } else if (item == "overview-v2") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/dashboard/overview-v2?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${api.startDate}&end_date=${api.endDate}`}
          >
            <a
              className={
                router.pathname === `/dashboard/overview-v2`
                  ? "nav-link active"
                  : ""
              }
            >
              Overview
            </a>
          </Link>
        </li>
      );
    } else if (item == "overviewBatteries") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/overviewBatteries?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${api.startDate}&end_date=${api.endDate}`}
          >
            <a
              className={
                router.pathname === `/overviewBatteries`
                  ? "nav-link active"
                  : ""
              }
            >
              Overview Storage
            </a>
          </Link>
        </li>
      );
    } else if (item == "readings") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/meter-readings/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}&s_ds=${s_ds}&show_global=${showGlobal}&sv=${showSV}&ev=${showEV}&st=${showST}&show_unnormalized=${show_unnormalized}&derived_value=Pwr_Norm&all_siblings=${false}&graph_mode=meterDiagnosis&zs=&ze=&reading_type=`}
          >
            <a
              className={
                router.pathname === "/meter-readings/[slug]"
                  ? "nav-link active"
                  : ""
              }
            >
              Analysis
            </a>
          </Link>
        </li>
      );
    } else if (item == "sibling") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/sibling-heatmap/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}&show_global=${showGlobal}&sv=${showSV}&ev=${showEV}&st=${showST}&show_unnormalized=${show_unnormalized}&derived_value=Pwr_Norm&all_siblings=${false}&graph_mode=siblingGraphs&zs=&ze=&reading_type=`}
          >
            <a
              className={
                router.pathname === "/sibling-heatmap/[slug]"
                  ? "nav-link active"
                  : ""
              }
            >
              Sibling Comparison
            </a>
          </Link>
        </li>
      );
    } else if (item == "states") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/states/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
            passHref={true}
          >
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={
                router.pathname.includes("/states/") ? "nav-link active" : ""
              }
            >
              States
            </a>
          </Link>
        </li>
      );
    } else if (item == "events") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/events/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/events/[slug]" ? "nav-link active" : ""
              }
            >
              Events
            </a>
          </Link>
        </li>
      );
    } else if (item == "rules") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/rules?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}`}
            passHref={true}
          >
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={router.pathname === "/rules" ? "nav-link active" : ""}
            >
              Rules
            </a>
          </Link>
        </li>
      );
    } else if (item == "snapshot") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/snapshot/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/snapshot/[slug]" ? "nav-link active" : ""
              }
            >
              Realtime Snapshot
            </a>
          </Link>
        </li>
      );
    } else if (item == "heatmap") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/meter-heatmaps/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}&show_global=${showGlobal}&sv=${showSV}&ev=${showEV}&st=${showST}&show_unnormalized=${show_unnormalized}&derived_value=Pwr_Norm&all_siblings=${false}&graph_mode=siblingHeatMap&zs=&ze=&reading_type=`}
          >
            <a
              className={
                router.pathname === "/meter-heatmaps/[slug]"
                  ? "nav-link active"
                  : ""
              }
            >
              Sibling Heat Maps
            </a>
          </Link>
        </li>
      );
    } else if (item == "operations") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/operations?plant_id=${api.plant_id}&start_date=${api.startDate}&end_date=${api.endDate}`}
          >
            <a
              className={
                router.pathname === "/operations" ? "nav-link active" : ""
              }
            >
              Tickets
            </a>
          </Link>
        </li>
      );
    } else if (item == "reports") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/reports?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/reports" ? "nav-link active" : ""
              }
            >
              Reports
            </a>
          </Link>
        </li>
      );
    } else if (item == "availability") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/availability/slug?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/availability/[slug]"
                  ? "nav-link active"
                  : ""
              }
            >
              Availability
            </a>
          </Link>
        </li>
      );
    } else if (item == "tmsReport") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/tms-report?start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/tms-report" ? "nav-link active" : ""
              }
            >
              TMS
            </a>
          </Link>
        </li>
      );
    } else if (item == "portfolioReport") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/portfolio-report?start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/portfolio-report" ? "nav-link active" : ""
              }
            >
              Monitoring
            </a>
          </Link>
        </li>
      );
    } else if (item == "scatterPlot") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/analytics/scatter-plot?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/analytics/scatter-plot"
                  ? "nav-link active"
                  : ""
              }
            >
              Scatter Plot
            </a>
          </Link>
        </li>
      );
    } else if (item == "statePlot") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/analytics/state-plots?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id != "undefined" ? slug.meter_id : 0}&start_date=${startDate}&end_date=${endDate}`}
          >
            <a
              className={
                router.pathname === "/analytics/state-plots"
                  ? "nav-link active"
                  : ""
              }
            >
              State Plot
            </a>
          </Link>
        </li>
      );
    } else if (item == "inventory") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/inventory`}>
            <a
              className={
                router.pathname === "/inventory" ? "nav-link active" : ""
              }
            >
              Inventory
            </a>
          </Link>
        </li>
      );
    } else if (item == "looker") {
      return (
        <li
          key={item}
          className={
            isHidden === true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href="/looker"
            className={router.pathname === `/looker` ? "nav-link active" : ""}
          >
            Looker
          </Link>
        </li>
      );
    } else if (item == "tickets-page-v2") {
      return (
        <li
          key={item}
          className={
            isHidden === true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link
            href={`/tickets-page-v2?start_date=${api.startDate}&end_date=${api.endDate}&plant_id=${api.plant_id}`}
          >
            <a
              className={
                router.pathname === `/tickets-page-v2` ? "nav-link active" : ""
              }
            >
              Tickets
            </a>
          </Link>
        </li>
      );
    } else if (item == "kpi-reports") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/reports/kpi`}>
            <a
              className={
                router.pathname === "/reports/kpi" ? "nav-link active" : ""
              }
            >
              KPI Report
            </a>
          </Link>
        </li>
      );
    } else if (item == "power-schedule") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/solar-power-schedule`}>
            <a
              className={
                router.pathname === "/solar-power-schedule"
                  ? "nav-link active"
                  : ""
              }
            >
              Power Schedule
            </a>
          </Link>
        </li>
      );
    } 
    else if (item == "power-billing") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          
          onClick={closeMobileMenu}
        >
          <Link href={`/solar-power-billing`}>
            <a
              className={
                router.pathname === "/solar-power-billing"
                  ? "nav-link active"
                  : ""
              }
            >
              Power Billing
            </a>
          </Link>
        </li>
      );
    }
    
    else if (item == "meter-blacklist") {
      return (
        <li
          key={item}
          className={
            isHidden == true ? "d-none d-xs-block d-none d-xs-block" : "option"
          }
          onClick={closeMobileMenu}
        >
          <Link href={`/meter-blacklist`}>
            <a
              className={
                router.pathname === "/meter-blacklist" ? "nav-link active" : ""
              }
            >
              Disable Meters
            </a>
          </Link>
        </li>
      );
    } 
    //  else if (item == 'notebooks') {
    //   return  (<li key={item} className={isHidden==true? 'd-none d-xs-block d-none d-xs-block': 'option' } onClick={closeMobileMenu}>
    //     <Link href={`/notebooks?plant_id=${api.plant_id}&meter_id=${slug.meter_id && slug.meter_id!="undefined"?slug.meter_id:0}&start_date=${startDate}&end_date=${endDate}`}>
    //       <a
    //
    //           className={
    //             router.pathname === "/notebooks" ? "nav-link active" : ""
    //           }
    //       >
    //         Notebooks
    //       </a>
    //     </Link>
    //   </li>)
    // }
  };

  return (
    <>
      <div className="header">
        <ul
          className={
            click
              ? "navoptions active mb-0"
              : "navoptions d-flex ml-0 pl-0 mb-0"
          }
        >
          {headerDashLinks.length > 0 ? (
            <>
              <div className="menudropdown d-none d-lg-block d-md-block">
                <button className="menudropbtn">
                  Dashboard <i className="fa fa-angle-down"></i>
                </button>
                <ul className="menudropdown-content">
                  {headerDashLinks.map((item, index) =>
                    getHeaderLink(item, false),
                  )}
                </ul>
              </div>
            </>
          ) : null}

          {headerAnalyticsLinks.length > 0 ? (
            <>
              <div className="menudropdown d-none d-lg-block d-md-block">
                <button className="menudropbtn">
                  Analytics <i className="fa fa-angle-down"></i>
                </button>
                <ul className="menudropdown-content">
                  {headerAnalyticsLinks.map((item, index) =>
                    getHeaderLink(item, false),
                  )}
                </ul>
              </div>
            </>
          ) : null}

          {headerDashLinks.map((item, index) => getHeaderLink(item, true))}

          {headerAnalyticsLinks.map((item, index) => getHeaderLink(item, true))}
          {headerLinks.map((item, index) => getHeaderLink(item, false))}
          {headerSubLinks.map((item, index) => getHeaderLink(item, true))}

          {headerSubLinks.length > 0 ? (
            <>
              <div className="menudropdown d-none d-lg-block d-md-block">
                <button className="menudropbtn">
                  More <i className="fa fa-angle-down"></i>
                </button>
                <ul className="menudropdown-content">
                  {headerSubLinks.map((item, index) =>
                    getHeaderLink(item, false),
                  )}
                  {headerSubLinks.map((item, index) =>
                    getHeaderSubLink(item, false),
                  )}
                </ul>
              </div>
            </>
          ) : (
            <></>
          )}
        </ul>
        <div id="mobilemenu" onClick={handleClick}>
          {click ? (
            <div className="menuicon close-topnav">
              <NewMenuIcon />
            </div>
          ) : (
            <div className="menuicon">
              <NewMenuIcon />
            </div>
          )}
        </div>
        <style jsx>
          {`
            .menudropbtn {
              background-color: transparent;
              color: #767676;
              padding: 16px;
              font-size: 16px;
              border: none;
              cursor: pointer;
              font-size: 1rem;
              font-weight: 600;
              zindex: 11;
            }

            .menudropdown {
              width: auto;
              position: relative;
              display: inline-block;
            }

            .menudropdown-content {
              display: none;
              position: absolute;
              background-color: #fff;
              min-width: 180px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 50;
              list-style: none;
              padding: 0;
              margin: 0;
            }

            .menudropdown-content a {
              color: #767676;
              padding: 12px 16px;
              text-decoration: none;
              display: block;
              font-size: 1rem;
              font-weight: 600;
              z-index: 50;
            }

            .menudropdown-content li {
              border-bottom: 1px solid #f7f7f7;
            }

            .menudropdown:hover .menudropdown-content {
              display: block;
              zindex: 11;
            }

            .header {
              display: flex;
              justify-content: space-between;
              align-items: start;
            }
            .logonav {
              display: flex;
              align-items: start;
              justify-content: space-evenly;
            }
            .logocontainer {
              display: flex;
              align-items: start;
              justify-content: center;
            }
            .logo {
              width: 45px;
              height: 45px;
            }
            .navoptions {
              list-style-type: none;
              // margin: auto;
            }
            .mobileoption {
              display: none;
            }

            .option :hover {
              color: #007bff;
            }
            a {
              font-size: 0.875rem;
              color: black;
            }
            .signinup {
              display: flex;
              padding: 0px 5px;
              list-style-type: none;
            }
            .signin {
              padding-right: 50px;
              align-self: center;
            }
            .signin :hover {
              color: white;
            }
            .signupbtn {
              padding: 10px 10px;
              height: 2.4rem;
              border-radius: 3px;
              background: rgb(222, 9, 241);
              color: white;
              cursor: pointer;
              align-items: center;
            }
            #mobilemenu {
              display: none;
            }
            .menuicon {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            @media (max-width: 800px) {
              /*Mobile View */

              .header {
                padding: 0px 10px;
              }
              .logo {
                width: 45px;
                height: 45px;
              }
              .navoptions {
                display: flex;
                width: 20rem;
                position: fixed;
                top: 60px;
                opacity: 1;
                flex-direction: column;
                list-style-type: none;
                grid-gap: 0px;
                transform: translateY(15px);
                z-index: -234567;
                transition: 0.3s transform;
                opacity: 0;
                left: 0;
              }

              .navoptions.active {
                background: white;
                opacity: 1;
                z-index: 150;
                padding-left: 0px;
                transform: translateY(0);
                -webkit-box-shadow: 0 0 10px 0 #ddd;
                box-shadow: 0 0 10px 0 #ddd;
              }

              .menuicon {
                width: 32px;
                height: 32px;
                padding: 4px;
              }
              .option {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // height: 10vw;
                // padding: 30px 0px;
              }
              .navoptions .option {
                padding: 0;
              }
              .signup {
                background: rgb(0, 0, 0, 1);
                border-radius: 3px;
                color: white;
                width: 100%;
                align-self: center;
                border-radius: 3px;
              }

              .signinup {
                display: none;
              }
              #mobilemenu {
                display: block;
              }
            }
          `}
        </style>
      </div>
     


      <Modal
        className="manualticketmodel"
        onClick={() => setBillingOpen(!billingOpen)}
        isOpen={billingOpen}
        style={{ display: "inline" }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
           Power Billing
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setBillingOpen(!billingOpen)

            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className=" modal-body">
          <ManualTicket />
        </div>
      </Modal>
    </>
  );
};

export default CustomNavbar;
